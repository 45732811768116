import { Category } from '@/model';

import { ALL_CATEGORIES_ID } from '../constants';

export const createAllCategoriesCategory = (name: string): Category => ({
  name,
  isActive: true,
  slug: ALL_CATEGORIES_ID,
  _id: ALL_CATEGORIES_ID,
});
