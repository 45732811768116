import { Category } from '@/model';

import { ALL_CATEGORIES_ID } from '../constants';
import { CatalogState } from '../types';

type Props = {
  loading: boolean;
  selectedCategory: Category;
  totalCount: number;
  searchKey?: string;
};

export const getCatalogState = ({
  loading,
  totalCount,
  selectedCategory,
  searchKey,
}: Props): CatalogState => {
  if (loading) return 'LOADING';

  if (
    totalCount === 0 &&
    selectedCategory._id === ALL_CATEGORIES_ID &&
    !searchKey
  )
    return 'NO_PRODUCTS_LOADED';

  if (totalCount === 0) return 'NO_PRODUCTS_FOUND';

  return 'PRODUCTS_FOUND';
};
