import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';

import { useBranding } from '@/shared/hooks';

import { LogoContainer } from './LogoContainer';

export const BigLogo: FC<React.PropsWithChildren<unknown>> = () => {
  const { isotype, name } = useBranding();
  return (
    <LogoContainer className="overflow-hidden flex items-center justify-center bg-white absolute rounded-full">
      {isotype && (
        <div className="relative h-full w-full">
          <CustomNextImage
            src={isotype}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt={name}
          />
        </div>
      )}
    </LogoContainer>
  );
};
