import { ProductFieldEnum } from '@/shared/types/graphql/globals.highereducation';

import { OrderDirectionEnum } from '../../shared/types/graphql/globals.highereducation';

import { SortOption } from './types';

export const HOMEPAGE_AMPLITUDE_PAGE_NAME = 'higher-education-home';
export const HOMEPAGE_PAGE_KEY = 'higher-education-home';

export const PRODUCTS_PER_PAGE = 12;
export const ALL_CATEGORIES_ID = 'all_categories';

export const ORDER_OPTIONS: SortOption[] = [
  { field: ProductFieldEnum.BEST_SELLER, direction: OrderDirectionEnum.DESC },
  { field: ProductFieldEnum.TITLE, direction: OrderDirectionEnum.ASC },
  { field: ProductFieldEnum.CREATED_AT, direction: OrderDirectionEnum.DESC },
];
