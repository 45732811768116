import { FC } from 'react';

import { Checkbox } from '@crehana/ui';

import { SortOption } from '@/views/Ecommerce/types';

import { useHomeTranslation } from '../../../../../hooks/useHomeTranslation';
import { FloatingMenuTitle } from '../../../../ui';

type Props = {
  options: SortOption[];
  selectedOption: SortOption;
  onSortOptionChange: (newOption: SortOption) => void;
};

export const SortOptionList: FC<React.PropsWithChildren<Props>> = ({
  options,
  selectedOption,
  onSortOptionChange,
}) => {
  const { t } = useHomeTranslation();

  const handleOptionChange = (option: SortOption) => {
    onSortOptionChange(option);
  };

  return (
    <>
      <FloatingMenuTitle title={t('SORT_BY')} />
      <ul>
        {options.map(option => (
          <li key={option.field}>
            <Checkbox
              checked={option === selectedOption}
              label={t(option.field)}
              onChange={() => handleOptionChange(option)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
