import { FC } from 'react';

import { Pagination } from '@/shared/components/ui';

import { BackToTop } from './BackToTop';

type Props = {
  currentPage: number;
  totalPages: number;

  onPageChange: (page: number) => void;
};

export const Footer: FC<React.PropsWithChildren<Props>> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center mx-16 lg:tw-mx-0 lg:tw-justify-end">
      <div className="mr-16 order-2 sm:order-1 mt-32 sm:mt-0">
        <BackToTop />
      </div>
      <div className="order-1 sm:order-2">
        <Pagination
          onPageChange={onPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};
