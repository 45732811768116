import { Category } from '@/model';

type Props = {
  selectedCategory: Category;
  categoryToApply: Category;
};
export const shouldDisableCategoryButton = ({
  categoryToApply,
  selectedCategory,
}: Props) => {
  return selectedCategory._id === categoryToApply._id;
};
