import { CatalogProduct } from '@/model';

import { CATEGORIES } from './categories';

export const CATALOG: CatalogProduct[] = [
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Aliqua Lorem est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[0],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Cliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[0],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Bliqua est esse Lorem ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Fliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Gliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Pliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Zliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Nliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Aliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[1],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Rliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[2],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Wliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[2],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Lliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[3],
  },
  {
    id: `${Math.floor(Math.random() * 1000000)}`,
    title: `Kliqua est esse ipsum sit irure commodo aute aute.`,
    currency: 'S',
    priceWithoutDiscount: 10,
    price: 200,
    image: 'https://linkiafp.es/app/uploads/2020/10/7.jpg',
    wasPurchased: false,
    category: CATEGORIES[4],
  },
];
