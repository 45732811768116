import { useCallback } from 'react';

import { Category } from '@/model';
import { useLazyFederationQuery, useOrganization } from '@/shared/hooks';
import { getOffset } from '@/shared/utils';
import { ALL_CATEGORIES_ID } from '@/views/Ecommerce/constants';

import { ORDER_OPTIONS, PRODUCTS_PER_PAGE } from '../constants';
import GET_PRODUCTS_QUERY from '../graphql/Query/GetProducts.highereducation.graphql';
import {
  ProductsQuery,
  ProductsQueryVariables,
} from '../graphql/Query/types/ProductsQuery';
import { SortOption } from '../types';
import { parseCatalogProducts } from '../utils';

type GetProductsProps = {
  first?: number;
  currentPage?: number;
  searchKey?: string;
  selectedOrder?: SortOption;
  category?: Category;
};

const DEFAULT_PROPS = {
  first: 12,
  currentPage: 1,
  searchKey: '',
  selectedOrder: ORDER_OPTIONS[0],
  category: undefined,
};

export const useProductsQuery = () => {
  const { organizationId } = useOrganization();
  const [getProductsMutation, { loading, data, error }] =
    useLazyFederationQuery<ProductsQuery, ProductsQueryVariables>(
      GET_PRODUCTS_QUERY,
    );

  const getProducts = useCallback(
    (props: GetProductsProps = DEFAULT_PROPS) => {
      const {
        first = DEFAULT_PROPS.first,
        searchKey = DEFAULT_PROPS.searchKey,
        selectedOrder = DEFAULT_PROPS.selectedOrder,
        currentPage = DEFAULT_PROPS.currentPage,
        category = DEFAULT_PROPS.category,
      } = props;

      return getProductsMutation({
        variables: {
          organizationId,
          first,
          searchKey,
          orderBy: [selectedOrder],
          after:
            currentPage > 1
              ? getOffset(currentPage, PRODUCTS_PER_PAGE)
              : undefined,
          categories:
            category && category._id !== ALL_CATEGORIES_ID
              ? category.slug
              : undefined,
        },
      });
    },
    [getProductsMutation, organizationId],
  );

  return {
    loading,
    error,
    products: parseCatalogProducts(data),
    getProducts,
  };
};
