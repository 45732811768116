import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Category } from '@/model';
import useMarketplaceAmplitudeEvent from '@/shared/hooks/useMarketplaceAmplitudeEvent';

import { useHomeTranslation } from '../../hooks';
import { ChangeCategoryEvent } from '../../types';
import { createAllCategoriesCategory } from '../../utils/createAllCategoriesCategory';

import { CategoryFilterContext } from './CategoryFilterContext';

type Props = {
  initialCategories?: Category[];
};

export const CategoryFilterProvider: FC<
  React.PropsWithChildren<PropsWithChildren<Props>>
> = ({ children, initialCategories = [] }) => {
  const { t } = useHomeTranslation();
  const logEvent = useMarketplaceAmplitudeEvent();
  const allCategories = useMemo(
    () => createAllCategoriesCategory(t('ALL_CATEGORIES_LIST')),
    [t],
  );

  const categories: Category[] = useMemo(
    () => [allCategories, ...initialCategories],
    [allCategories, initialCategories],
  );

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    categories[0],
  );

  const changeCategory = useCallback(
    (category: Category) => {
      if (category.slug === selectedCategory.slug) return;

      logEvent<ChangeCategoryEvent>('Click Category eCommerce', {
        category: category.name,
      });
      setSelectedCategory(category);
    },
    [logEvent, selectedCategory.slug],
  );

  return (
    <CategoryFilterContext.Provider
      value={{
        allCategories,
        categories,
        selectedCategory,

        changeCategory,
      }}
    >
      {children}
    </CategoryFilterContext.Provider>
  );
};
