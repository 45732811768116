import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';

import { useBranding } from '@/shared/hooks';

import { BannerContainer } from './BannerContainer.style';

export const Banner: FC<React.PropsWithChildren<unknown>> = () => {
  const { banner, name } = useBranding();

  return (
    <BannerContainer
      role={'banner'}
      className="w-full relative hidden sm:block"
    >
      {banner && (
        <CustomNextImage
          objectFit="cover"
          layout="fill"
          src={banner}
          alt={name}
        />
      )}
    </BannerContainer>
  );
};
