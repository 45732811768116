import { Category } from '@/model/Category';

export const isSelected = (
  currentCategory: Category,
  selectedCategory: Category | null,
) => {
  if (!selectedCategory) return currentCategory._id === 'all_categories';

  return selectedCategory._id === currentCategory._id;
};
