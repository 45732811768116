import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAmplitude } from '@crehana/analytics';

import { capitalize } from '../../../scripts/routes_generator/utils/capitalize';
import { AmplitudeEvent } from '../types';

import useFlagOrganization from './useFlagOrganization';
import { useOrganization } from './useOrganization';

export default function useMarketplaceAmplitudeEvent() {
  const { logEvent, amplitude } = useAmplitude();
  const { query } = useRouter();
  const { isActive, isLoading } = useFlagOrganization({
    flag: 'marketplace-events',
  });
  const { organizationSlug, organizationId } = useOrganization();

  const logMarketplaceEvent = useCallback(
    <T extends AmplitudeEvent>(
      event: T['event'],
      extraData: Omit<
        T['data'],
        'organizationSlug' | 'organizationId' | 'source' | 'sourceDetail'
      >,
      options: { omitSource?: boolean } = {},
    ) => {
      if (!amplitude || !isActive || isLoading) return;

      const sourceDetail = query['sourceDetail']
        ? capitalize(decodeURI(query['sourceDetail'].toString()))
        : undefined;

      logEvent({
        event,
        data: {
          organizationSlug,
          organizationId,
          source: !options.omitSource ? query['source'] : undefined,
          sourceDetail: !options.omitSource ? sourceDetail : undefined,
          ...extraData,
        },
      });
    },
    [
      amplitude,
      isActive,
      isLoading,
      logEvent,
      organizationSlug,
      organizationId,
      query,
    ],
  );

  return logMarketplaceEvent;
}
