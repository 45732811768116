import { FC } from 'react';

import { EyeOff } from '@crehana/icons';
import { colors } from '@crehana/ui';

import { useHomeTranslation } from '../../hooks';

type Props = {};

export const NoProductsUploaded: FC<React.PropsWithChildren<Props>> = () => {
  const { t } = useHomeTranslation();
  return (
    <div className="flex flex-col items-center justify-center mt-104">
      <EyeOff className="mb-16" size={40} color={colors.grayDark} />
      <p className="font-subtitle3 text-gray-dark w-280 text-center">
        {t('NO_PRODUCTS_UPLOADED')}
      </p>
    </div>
  );
};
