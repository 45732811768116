import { FC } from 'react';

import { useHomeTranslation } from '../../../hooks/useHomeTranslation';
import { SearchResultSkeleton } from '../../ui';

import { SortDropdownDesktop } from './SortDropdownDesktop';

type Props = {
  loading: boolean;
  productsCount: number;
};

export const CatalogSubheader: FC<React.PropsWithChildren<Props>> = ({
  loading,
  productsCount,
}) => {
  const { t } = useHomeTranslation();

  return (
    <div
      className="flex items-center justify-between mb-24 w-full lg:tw-mb-4"
      style={{ color: '#73757A' }}
    >
      {loading ? (
        <SearchResultSkeleton />
      ) : (
        <>
          <span className="font-subtitle4 mx-auto sm:mx-0">
            {productsCount}
            {` ${t('SEARCH_RESULTS')}`}
          </span>
          <div className="hidden lg:tw-flex">
            <SortDropdownDesktop />
          </div>
        </>
      )}
    </div>
  );
};
