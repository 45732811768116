import styled from 'styled-components';

export const CatalogContainer = styled.div`
  @media (max-width: 768px) {
    min-height: 350px;
  }

  @media (min-width: 768px) {
    min-height: 400px;
  }

  @media (min-width: 1366px) {
    min-height: 484px;
  }
`;
