import { useEffect } from 'react';

import withErrorViewsOnApp from '@/shared/components/hoc/withErrorHandling';
import useMarketplaceAmplitudeEvent from '@/shared/hooks/useMarketplaceAmplitudeEvent';

import { Catalog } from './components/Catalog';
import { Banner, BigLogo } from './components/ui';
import { CategoryFilterProvider, SortingProvider } from './context';
import type { ComplementAppProps, RenderMarketplaceEvent } from './types';

const App = withErrorViewsOnApp<ComplementAppProps>(
  props => {
    const { categories } = props;
    const logEvent = useMarketplaceAmplitudeEvent();

    useEffect(() => {
      logEvent<RenderMarketplaceEvent>('Render Marketplace', {});
    }, [logEvent]);

    return (
      <>
        <div id="main-cotent" style={{ position: 'absolute', top: -72 }} />

        <div className="hidden lg:tw-block">
          <BigLogo />
        </div>
        <section className="text-18 flex" title="Market">
          <CategoryFilterProvider initialCategories={categories}>
            <SortingProvider>
              <Catalog />
            </SortingProvider>
          </CategoryFilterProvider>
        </section>
      </>
    );
  },
  () => ({
    banner: <Banner />,
  }),
);

export default App;
