import styled from 'styled-components';

export const FloatingFilterContainer = styled.div`
  border-radius: 50px;
  border: 1px solid #0f1019;
  position: fixed;
  bottom: 60px;
  margin: 0 auto;
  width: 312px;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 10;
`;
