import styled from 'styled-components';

export const ProductsListContainer = styled.ul`
  display: grid;
  gap: 48px 24px;

  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
