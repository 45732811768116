import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { CustomNextImage } from '@crehana/next';

import { CatalogProduct } from '@/model/CatalogProduct';
import { InternalLink, TextWithEllipsis } from '@/shared/components/ui';
import Button from '@/shared/components/ui/Button';
import { useMyTrackUrl } from '@/shared/hooks/useMyTracksUrl';
import { OrderStatus } from '@/shared/types';
import { useHomeTranslation } from '@/views/Ecommerce/hooks';

import { getButtonText } from './getButtonText';
import { ImageContainer } from './ImageContainer.styles';
import { InnerShadow } from './ProductCardInnerShadow.styles';

type Props = {
  product: CatalogProduct;
  orderStatus?: OrderStatus;
};
export const ProductCard: FC<React.PropsWithChildren<Props>> = ({
  product,
  orderStatus,
}) => {
  const { t } = useHomeTranslation();
  const tracksUrl = useMyTrackUrl();
  const { asPath } = useRouter();
  const { image, title, price, currency, priceWithoutDiscount } = product;

  return (
    <article
      className="rounded-10 flex flex-col shadow-1-dp pb-20 h-full tw-bg-neutral-light-100"
      title="Product card"
    >
      <div className="w-full bg-base-lighter-56 rounded-t-10 relative overflow-hidden mb-16">
        <ImageContainer>
          <CustomNextImage
            title={title}
            alt={title}
            src={image}
            layout="fill"
            objectFit="cover"
          />
          <InnerShadow />
        </ImageContainer>
      </div>
      <div className="px-20 flex-grow flex flex-col">
        <header className="flex-grow">
          <h3 className="font-subtitle3 text-14 leading-24 mb-2 overflow-hidden">
            <TextWithEllipsis>{title}</TextWithEllipsis>
          </h3>
        </header>
        <div className="flex items-center mt-8 mb-20">
          <p className="font-currency text-red-main">
            {currency}/ {price.toLocaleString()}
          </p>
          {price < priceWithoutDiscount && (
            <p className="font-discount text-gray-dark">
              {currency}/ {priceWithoutDiscount.toLocaleString()}
            </p>
          )}
        </div>

        {orderStatus === 'PAID' ? (
          <Link href={tracksUrl}>
            <Button full>{t(getButtonText(orderStatus))}</Button>
          </Link>
        ) : (
          <InternalLink
            href={{
              pathname: `ecommerce/product/${product.id}`,
              query: {
                source: asPath,
                sourceDetail: encodeURI('homepage card click'),
              },
            }}
            passHref
          >
            <Button full>{t(getButtonText(orderStatus))}</Button>
          </InternalLink>
        )}
      </div>
    </article>
  );
};
