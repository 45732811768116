import { Category } from '../model/Category';

export const CATEGORIES: Category[] = [
  {
    _id: '1',
    name: 'Marketing Digital',
    slug: 'marketing-digital',
    isActive: true,
  },
  {
    _id: '2',
    name: 'Ilustracion Digital',
    slug: 'ilutracion-digital',
    isActive: true,
  },
  { _id: '3', name: 'Negocios', slug: 'negocios', isActive: true },
  { _id: '4', name: 'Liderazgo', slug: 'liderazgo', isActive: true },
  { _id: '6', name: 'Soft Skills', slug: 'soft-skills', isActive: true },
  {
    _id: '5',
    name: 'Diseño Grafico',
    slug: 'diseno-grafico',
    isActive: true,
  },
  {
    _id: '7',
    name: 'Dibujo y Pintura',
    slug: 'dibujo-y-pintura',
    isActive: true,
  },
];
