import { FC, useContext, useMemo } from 'react';

import { CuiOptionType } from '@crehana/ui';

import { Dropdown } from '@/shared/components/ui';

import { SortingContext } from '../../../../context/Sorting/SortingContext';
import { useHomeTranslation } from '../../../../hooks/useHomeTranslation';

export const SortDropdownDesktop: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useHomeTranslation();
  const { sortBy, selectedOrder, orders } = useContext(SortingContext);

  const options = useMemo(
    () =>
      orders.map(({ field }) => ({
        label: t(field),
        value: field,
      })),
    [t, orders],
  );

  const selectedOption = useMemo(
    () => options.find(option => option.value === selectedOrder.field),
    [options, selectedOrder],
  );

  const handleSelected = (newValue?: CuiOptionType) => {
    const newSort = orders.find(option => option.field === newValue?.value);
    if (newSort) {
      sortBy(newSort);
    }
  };

  return (
    <div className="font-body4 items-center flex">
      <span aria-label="Sort List">{t('SORT_BY').toUpperCase()}</span>
      <Dropdown
        className="ml-16"
        options={options}
        selected={selectedOption}
        onSelected={handleSelected}
      />
    </div>
  );
};
