import { HOMEPAGE_PAGE_KEY } from '@/views/Ecommerce/constants';

const pt = {
  [HOMEPAGE_PAGE_KEY]: {
    BUY_NOW: 'Comprar agora',
    CATALOG_ROUTES_CATEGORY_LABEL: 'Rotas de',
    FILTER_BUTTON: 'Filtro',
    ORDER_BUTTON: 'Organizar',
    FILTER_RESULTS: 'Filtrar Resultados',
    CATEGORIES_LABEL: 'Categorias',
    ALL_CATEGORIES_LIST: 'Todas as categorias',
    SEARCH_RESULTS: 'Resultados',
    BACK_TO_TOP: 'Voltar ao início',
    SORT_BY: 'Ordenar por',
  },
};

export default pt;
