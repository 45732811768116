import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import GET_FEATURE_FLAG from '@/shared/graphql/GetFlagOrganizationQuery.v4.b2b.graphql';
import type {
  GetFlagOrganizationQuery,
  GetFlagOrganizationQueryVariables,
} from '@/shared/graphql/types/GetFlagOrganizationQuery';

type useFlagOrganizationProps = {
  flag: string;
};

const useFlagOrganization = ({ flag }: useFlagOrganizationProps) => {
  const { query } = useRouter();
  const { slug } = query as { slug: string };

  const { data, loading } = useQuery<
    GetFlagOrganizationQuery,
    GetFlagOrganizationQueryVariables
  >(GET_FEATURE_FLAG, {
    variables: { slug },
    context: { clientName: 'v4.b2b' },
    fetchPolicy: 'network-only',
  });

  const currentFlag = data?.b2bOrganizationCurrent?.features?.find(
    feature => feature?.slug === flag,
  );

  return {
    isActive: currentFlag?.isActive || false,
    isLoading: loading,
  };
};

export default useFlagOrganization;
