import { CatalogProduct } from '@/model/CatalogProduct';

import { ProductsQuery } from '../graphql/Query/types/ProductsQuery';

export const parseCatalogProducts = (
  data?: ProductsQuery,
): CatalogProduct[] => {
  if (!data) return [];

  const productsFiltered: CatalogProduct[] = [];

  data.products.edges.forEach(
    ({
      node: {
        id,
        title,
        local_currency,
        local_amount,
        local_fake_amount,
        cover_image,
      },
    }) => {
      if (
        title !== null &&
        local_currency !== null &&
        local_amount !== null &&
        local_fake_amount !== null
      )
        productsFiltered.push({
          id,
          title,
          image: cover_image?.url || '',
          price: local_amount,
          currency: local_currency,
          priceWithoutDiscount: local_fake_amount,
          wasPurchased: false,
          category: {
            _id: '',
            name: 'string',
            slug: '',
            isActive: true,
          },
        });
    },
  );

  return productsFiltered;
};
