import { FC, useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { OrderStatusEnum, useUserOrders } from '@/shared/hooks';
import useMarketplaceAmplitudeEvent from '@/shared/hooks/useMarketplaceAmplitudeEvent';

import { PRODUCTS_PER_PAGE } from '../../constants';
import { CategoryFilterContext } from '../../context';
import { useCatalog } from '../../hooks';
import { SearchEvent } from '../../types';
import { getCatalogState } from '../../utils';
import { NoProductsFound, NoProductsUploaded, ProductList } from '../ui';

import {
  CatalogContainer,
  CatalogHeader,
  CatalogSubheader,
  CategoryFilterDesktop,
  FloatingFilterAndOrderButton,
  Footer,
} from './components';
import { scrollTo } from './utils';

export const Catalog: FC<React.PropsWithChildren<unknown>> = () => {
  const { query } = useRouter();
  const { search } = query;
  const logEvent = useMarketplaceAmplitudeEvent();

  const searchQuery = useMemo(
    () => (search ? decodeURI(search as string) : undefined),
    [search],
  );

  const { selectedCategory } = useContext(CategoryFilterContext);
  const { loading, products, totalCount, currentPage, getPage } =
    useCatalog(searchQuery);

  const { orders } = useUserOrders([
    OrderStatusEnum.PAID,
    OrderStatusEnum.PENDING,
  ]);

  const handlePageChange = (page: number) => {
    getPage(page);
    scrollTo('main-cotent');
  };

  const totalPages = Math.ceil(totalCount / PRODUCTS_PER_PAGE);

  const state = getCatalogState({
    loading,
    selectedCategory,
    searchKey: searchQuery,
    totalCount,
  });

  useEffect(() => {
    if (!search || loading) return;

    logEvent<SearchEvent>('Marketplace Search Results', {
      searchResults: totalCount,
      searchTerm: search.toString(),
    });
  }, [loading, logEvent, search, totalCount]);

  return (
    <article className="flex w-full relative flex-col" title="Catalog">
      <div className="flex flex-col items-center sm:items-start lg:tw-ml-28 lg:tw-pl-2">
        <CatalogHeader currentCategory={selectedCategory} />
        <CatalogSubheader loading={loading} productsCount={totalCount} />
      </div>
      <CatalogContainer className="flex items-start">
        <aside
          className="w-280 h-full flex-shrink-0 sticky hidden lg:tw-block"
          style={{ top: 80 }}
        >
          <CategoryFilterDesktop />
        </aside>

        <div className="flex flex-col flex-grow h-full lg:tw-ml-2">
          <div className="flex-grow w-full">
            {state === 'NO_PRODUCTS_LOADED' && <NoProductsUploaded />}
            {state === 'NO_PRODUCTS_FOUND' && <NoProductsFound />}
            {(state === 'LOADING' || state === 'PRODUCTS_FOUND') && (
              <>
                <ProductList
                  products={products}
                  loading={loading}
                  orders={orders}
                />

                <Footer
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </div>
          <FloatingFilterAndOrderButton />
        </div>
      </CatalogContainer>
    </article>
  );
};
