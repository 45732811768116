import styled from 'styled-components';

export const InnerShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(58, 62, 77, 0.2) 47.84%,
    rgba(4, 9, 28, 0.3) 67.71%,
    rgba(4, 9, 28, 0.8) 100%
  );
`;
