import { SortOption } from '../../../../../types';

type Props = {
  orderToApply: SortOption;
  selectedOrder: SortOption;
};

export const shouldDisableOrderButton = ({
  orderToApply,
  selectedOrder,
}: Props) => orderToApply === selectedOrder;
