import { FC } from 'react';

import { Filter } from '@crehana/icons/outline';

import { useHomeTranslation } from '../../../../../hooks/useHomeTranslation';

import { FloatingButton } from './FloatingButton';
import { FloatingFilterContainer } from './FloatingFilterContainer';

type Props = {
  onCategoryClick: () => void;
  onSortingClick: () => void;
};

export const FloatingButtons: FC<React.PropsWithChildren<Props>> = ({
  onCategoryClick,
  onSortingClick,
}) => {
  const { t } = useHomeTranslation();
  return (
    <FloatingFilterContainer>
      <FloatingButton
        text={t('FILTER_BUTTON')}
        icon={<Filter size={16} />}
        onClick={onCategoryClick}
      />

      <span className="border-l border-solid h-full" />

      <FloatingButton
        text={t('ORDER_BUTTON')}
        icon={<Filter size={16} />}
        onClick={onSortingClick}
      />
    </FloatingFilterContainer>
  );
};
