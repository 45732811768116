import { OrderStatus } from '@/shared/types';
import { TKeys } from '@/views/Ecommerce/i18n';

const buttonText: Partial<Record<OrderStatus, TKeys>> = {
  PAID: 'GO_TO_PATH',
  PENDING: 'WATCH_PATH',
};

export const getButtonText = (status: OrderStatus = 'UNKNOWN'): TKeys =>
  buttonText[status] ?? 'BUY_NOW';
