import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { Category } from '@/model';
import CATEGORIES_QUERY from '@/shared/graphql/PathCategoriesQuery.highereducation.graphql';
import {
  PathCategoriesQuery,
  PathCategoriesQueryVariables,
} from '@/shared/graphql/types/PathCategoriesQuery';
import { Organization } from '@/shared/types';
import InternalError from '@/shared/utils/InternalError';

import { federationApolloQuery } from '../../services/federationApolloQuery';

type Props = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  organization: Organization;
};

export const getEcommerceCategories = async ({
  apolloClient,
  organization,
}: Props): Promise<Category[]> => {
  const { id, slug, domain } = organization;
  try {
    const { data, error } = await federationApolloQuery<
      PathCategoriesQuery,
      PathCategoriesQueryVariables
    >({
      apolloClient,
      options: {
        query: CATEGORIES_QUERY,
        variables: {
          organizationId: id,
        },
      },
    });

    if (error)
      throw new InternalError('CATEGORIES_QUERY_ERROR', { slug, domain });

    const categories = data.product_categories.map(
      ({ id, slug, name, is_active }) => ({
        _id: id,
        slug,
        name,
        isActive: is_active,
      }),
    );

    return categories;
  } catch {
    return [];
  }
};
