import { FC, PropsWithChildren } from 'react';

import { CloseSmall } from '@crehana/icons/outline';

import { PrimaryButton } from '@/shared/components/ui';

import { FloatingMenuContent } from './FloatingMenuContent';
import { FloatingMenuWrapper } from './FloatingMenuWrapper';

type Props = {
  isOpen: boolean;
  isButtonDisabled: boolean;

  onButtonClicked: () => void;
  onMenuClosed: () => void;
};

export const FloatingMenu: FC<
  React.PropsWithChildren<PropsWithChildren<Props>>
> = ({ children, isOpen, isButtonDisabled, onMenuClosed, onButtonClicked }) => (
  <div className="w-full flex justify-center relative lg:tw-hidden">
    <FloatingMenuWrapper show={isOpen}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="w-full h-full" onClick={onMenuClosed} />
      <FloatingMenuContent>
        <div className="w-full flex flex-col mt-16">
          <div className="flex items-center ml-16">
            <button type="button" onClick={onMenuClosed}>
              <CloseSmall />
            </button>
          </div>
          <div className="px-16 h-full">{children}</div>
          <div className="mt-auto border-t border-solid border-gray-light p-16">
            <PrimaryButton
              onClick={onButtonClicked}
              text={'Aplicar filtros'}
              disabled={isButtonDisabled}
            />
          </div>
        </div>
      </FloatingMenuContent>
    </FloatingMenuWrapper>
  </div>
);
