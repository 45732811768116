import { createContext } from 'react';

import { SortOption } from '../../types';

interface ContextProps {
  selectedOrder: SortOption;
  orders: SortOption[];

  sortBy: (order: SortOption) => void;
}

export const SortingContext = createContext({} as ContextProps);
