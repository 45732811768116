import { HOMEPAGE_PAGE_KEY } from '@/views/Ecommerce/constants';

const es = {
  [HOMEPAGE_PAGE_KEY]: {
    PAGE_TITLE: 'Marketplace',
    PAGE_DESCRIPTION:
      'Certifícate, alcanza tus objetivos y mejora tus habilidades con nuestros packs de cursos con más de 40% de descuento. ¡Aprende hoy y aplica hoy!',

    BUY_NOW: 'Comprar ahora',
    CATALOG_ROUTES_CATEGORY_LABEL: 'Rutas de',
    FILTER_BUTTON: 'Filtrar',
    ORDER_BUTTON: 'Ordenar',
    FILTER_RESULTS: 'Filtrar Resultados',
    CATEGORIES_LABEL: 'Categorías',
    ALL_CATEGORIES_LIST: 'Todas las categorías',
    SEARCH_RESULTS: 'Resultados',
    BACK_TO_TOP: 'Volver arriba',
    SORT_BY: 'Ordenar por',
    CREATED_AT: 'Fecha',
    TITLE: 'Alfabético',
    BEST_SELLER: 'Mas vendidos',
    NO_PRODUCTS_UPLOADED:
      'No tenemos rutas de aprendizaje para ofrecerte en este momento.',
    NO_PRODUCTS_FOUND:
      'No tenemos rutas de aprendizaje que coincidan con lo que estás buscando.',
    RECOMENDED_PRODUCTS: 'Rutas recomendadas',
    GO_TO_PATH: 'Ir a ruta',
    WATCH_PATH: 'Ver ruta',
  },
};

export default es;
