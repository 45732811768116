import { FC } from 'react';

import { PRODUCTS_PER_PAGE } from '../../../constants';
import { ProductsListContainer } from '../ProductsListContainer';

import { ProductCardSkeleton } from './ProductCardSkeleton';

type Props = {
  amount?: number;
};

export const ProductListSkeleton: FC<React.PropsWithChildren<Props>> = ({
  amount = PRODUCTS_PER_PAGE,
}) => (
  <ProductsListContainer title="Loading Catalog" className="w-full mb-40">
    {Array(amount)
      .fill(1)
      .map(() => (
        <li key={`skeleton-${Math.random()}`}>
          <ProductCardSkeleton />
        </li>
      ))}
  </ProductsListContainer>
);
