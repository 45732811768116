import { useContext, useEffect, useMemo, useState } from 'react';

import { CatalogProduct } from '@/model/CatalogProduct';
import { useFederationQuery, useOrganization } from '@/shared/hooks';
import { getOffset } from '@/shared/utils';

import { PRODUCTS_PER_PAGE } from '../constants';
import { CategoryFilterContext } from '../context/CategoryFilter/CategoryFilterContext';
import { SortingContext } from '../context/Sorting/SortingContext';
import GET_PRODUCTS_QUERY from '../graphql/Query/GetProducts.highereducation.graphql';
import {
  ProductsQuery,
  ProductsQueryVariables,
} from '../graphql/Query/types/ProductsQuery';

export const useCatalog = (searchKey?: string) => {
  const { organizationId } = useOrganization();
  const { selectedCategory, allCategories } = useContext(CategoryFilterContext);
  const { selectedOrder } = useContext(SortingContext);
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, data } = useFederationQuery<
    ProductsQuery,
    ProductsQueryVariables
  >(GET_PRODUCTS_QUERY, {
    variables: {
      organizationId,
      first: 12,
      isDraft: false,
      after:
        currentPage > 1 ? getOffset(currentPage, PRODUCTS_PER_PAGE) : undefined,
      searchKey,
      orderBy: [selectedOrder],
      categories:
        selectedCategory._id !== allCategories._id
          ? selectedCategory.slug
          : undefined,
    },
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  const totalCount = data?.products.total_count || 0;
  const hasNextPage = data?.products.page_info.has_next_page || false;
  const hasPreviousPage = data?.products.page_info.has_previous_page || false;

  const products: CatalogProduct[] = useMemo(() => {
    if (!data) return [];

    const productsFiltered: CatalogProduct[] = [];

    data.products.edges.forEach(
      ({
        node: {
          id,
          title,
          local_currency,
          local_amount,
          local_fake_amount,
          cover_image,
        },
      }) => {
        if (
          title !== null &&
          local_currency !== null &&
          local_amount !== null &&
          local_fake_amount !== null
        )
          productsFiltered.push({
            id,
            title,
            image: cover_image?.url || '',
            price: local_amount,
            currency: local_currency,
            priceWithoutDiscount: local_fake_amount,
            wasPurchased: false,
            category: {
              _id: '',
              name: 'string',
              slug: '',
              isActive: true,
            },
          });
      },
    );

    return productsFiltered;
  }, [data]);

  const nextPage = () => {
    if (hasNextPage) setCurrentPage(currentPage + 1);
  };

  const previousePage = () => {
    if (hasPreviousPage) setCurrentPage(currentPage - 1);
  };

  const getPage = (page: number) => {
    if (page <= 0) return;

    setCurrentPage(page);
  };

  return {
    loading,
    products,
    totalCount,
    currentPage,
    hasNextPage,
    hasPreviousPage,

    nextPage,
    previousePage,
    getPage,
  };
};
