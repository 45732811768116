import { FC } from 'react';

type Props = {
  title: string;
};

export const FloatingMenuTitle: FC<React.PropsWithChildren<Props>> = ({
  title,
}) => (
  <div className="pt-16 pb-32 border-solid border-gray-main lg:tw-mb-1.6 lg:tw-border-t lg:tw-border-b lg:tw-py-1.6">
    <p className="font-label lg:font-body4 font-semibold text-gray-dark uppercase lg:tw-normal-case">
      {title}
    </p>
  </div>
);
