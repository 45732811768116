import { ecommercePropsWithCallback } from '@/shared/server';
import { EcommerceGetServerSideProps } from '@/shared/server/types';
import { getEcommerceCategories } from '@/shared/services/ecommerce/getEcommerceCategories';

import { HOMEPAGE_PAGE_KEY as pageKey } from '../constants';
import locales from '../i18n';
import { ComplementAppProps } from '../types';

const getServerSideProps: EcommerceGetServerSideProps<
  ComplementAppProps
> = async ({ req, params }) =>
  await ecommercePropsWithCallback<ComplementAppProps>({
    req,
    params,
    pageKey,
    locales,
    callback: async ({
      apolloClient,
      organization,
      branding,
      g11nDataPromise,
    }) => {
      const categoriesPromise = getEcommerceCategories({
        apolloClient,
        organization,
      });

      const [g11nData, categories] = await Promise.all([
        g11nDataPromise,
        categoriesPromise,
      ]);

      return {
        organization,
        branding,
        categories,
        ...g11nData,
      };
    },
  });

export default getServerSideProps;
