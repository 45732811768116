import { FC } from 'react';

export const ProductCardSkeleton: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <article className="rounded flex flex-col">
      <div className="h-200 w-full bg-base-lighter-16 rounded-t-10 relative overflow-hidden mb-16" />
      <div className="px-20">
        <header>
          <div className="h-16 w-full bg-base-lighter-16"></div>
          <div className="h-16 w-1/2 bg-base-lighter-16 mt-8"></div>
        </header>
        <div className="flex items-center mt-20 mb-20">
          <div className="h-16 w-1/2 bg-base-lighter-16"></div>
        </div>

        <div className="h-48 w-full bg-base-lighter-16 mb-24 rounded-10"></div>
      </div>
    </article>
  );
};
