import styled from 'styled-components';

export const BannerContainer = styled.div`
  height: 288px;

  @media (min-width: 768px) {
    height: 300px;
  }

  @media (min-width: 1366px) {
    height: 392px;
  }
`;
