import { FC, ReactNode } from 'react';

type Props = {
  text: string;
  icon: ReactNode;
  onClick: () => void;
};

export const FloatingButton: FC<React.PropsWithChildren<Props>> = ({
  text,
  icon,
  onClick,
}) => {
  return (
    <button
      type={'button'}
      className="flex-grow font-link flex items-center justify-center h-full w-full"
      onClick={onClick}
    >
      {icon}
      <span className="ml-8">{text}</span>
    </button>
  );
};
