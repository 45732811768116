import { FC, useEffect } from 'react';

import { Search } from '@crehana/icons';
import { colors } from '@crehana/ui';

import { useHomeTranslation } from '../../hooks';
import { useProductsQuery } from '../../hooks/useProductsQuery';

import { ProductList } from './ProductList';

export const NoProductsFound: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useHomeTranslation();
  const { getProducts, loading, products } = useProductsQuery();

  useEffect(() => {
    getProducts({ first: 6 });
  }, [getProducts]);

  return (
    <div className="w-full h-full">
      <div className="flex flex-col items-center justify-center mb-144 mt-48">
        <Search className="mb-16" size={40} color={colors.grayDark} />
        <p className="font-subtitle3 text-gray-dark text-center w-280 sm:w-360">
          {t('NO_PRODUCTS_FOUND')}
        </p>
      </div>
      <div>
        <h3 className="font-subtitle3 mb-36">{t('RECOMENDED_PRODUCTS')}</h3>
        <ProductList products={products} loading={loading} />
      </div>
    </div>
  );
};
