import { FC } from 'react';

import { CatalogProduct } from '@/model';
import { Order } from '@/shared/types';
import { getProductOrderFromOrders } from '@/shared/utils/getProductOrderFromOrders';

import { ProductListSkeleton } from './Skeletons/ProductListSkeleton';
import { ProductCard } from './ProductCard';
import { ProductsListContainer } from './ProductsListContainer';

type Props = {
  products: CatalogProduct[];
  loading: boolean;
  orders?: Order[];
};

export const ProductList: FC<React.PropsWithChildren<Props>> = ({
  products,
  loading,
  orders = [],
}) => {
  if (loading) return <ProductListSkeleton />;

  return (
    <ProductsListContainer className="w-full mb-40" title="Products list">
      {products.map(product => (
        <li key={product.id}>
          <ProductCard
            product={product}
            orderStatus={getProductOrderFromOrders({ product, orders })?.status}
          />
        </li>
      ))}
    </ProductsListContainer>
  );
};
