import styled from 'styled-components';

export const FloatingMenuWrapper = styled.div<{ show: boolean }>`
  z-index: 999;
  position: fixed;
  display: flex;
  top: 0;
  left: ${props => {
    return props.show ? '0' : '100%';
  }};
  width: 100vw;
  height: 100vh;
  transition: left 0.2s ease;
  background: rgba(24, 27, 50, 0.8);

  @media (min-width: 768px) {
    background: rgba(0, 0, 0, 0.9);
  }
`;
