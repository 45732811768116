import { FC, useContext } from 'react';

import { CategoryFilterContext } from '../../../../context';
import { CategoryList } from '../../../ui';

export const CategoryFilterDesktop: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { categories, selectedCategory, changeCategory } = useContext(
    CategoryFilterContext,
  );

  return (
    <CategoryList
      categories={categories}
      selectedCategory={selectedCategory}
      onCategoryChange={changeCategory}
    />
  );
};
