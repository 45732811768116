import styled from 'styled-components';

export const CategoryName = styled.h4<{
  isSelected: boolean;
  primaryColor: string;
}>`
  border-bottom: ${props =>
    props.isSelected ? `2px solid ${props.primaryColor}` : 'none'};

  font-weight: ${props => (props.isSelected ? 600 : 'inherit')};
`;
