import styled from 'styled-components';

export const FloatingMenuContent = styled.div`
  background-color: white;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 308px;
  height: 100vh;
`;
