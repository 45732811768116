import { FC, useContext, useState } from 'react';

import { Category } from '@/model';
import { ORDER_OPTIONS } from '@/views/Ecommerce/constants';

import { CategoryFilterContext } from '../../../../context/CategoryFilter';
import { SortingContext } from '../../../../context/Sorting/SortingContext';
import { SortOption } from '../../../../types';
import { CategoryList } from '../../../ui';

import { FloatingMenu } from './components/FloatingMenu';
import { SortOptionList } from './components/SortOptionList';
import { shouldDisableCategoryButton } from './utils/shouldDisabledCategoryButton';
import { shouldDisableOrderButton } from './utils/shouldDisabledSortButton';
import { FloatingButtons } from './components';

type SelectedFilter = 'filter' | 'order' | 'none';

export const FloatingFilterAndOrderButton: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedFilter, setselectedFilter] = useState<SelectedFilter>('none');

  const { categories, selectedCategory, changeCategory } = useContext(
    CategoryFilterContext,
  );

  const { selectedOrder, sortBy } = useContext(SortingContext);

  const [categoryToApply, setCategoryToApply] =
    useState<Category>(selectedCategory);
  const [orderToApply, setOrderToApply] = useState<SortOption>(selectedOrder);

  const closeMenu = () => {
    setOpenMenu(false);
    setselectedFilter('none');
  };

  const onCategoryFilterClick = () => {
    setOpenMenu(true);
    setselectedFilter('filter');
  };

  const onSortingClick = () => {
    setOpenMenu(true);
    setselectedFilter('order');
  };

  const handleFilterApplied = () => {
    if (categoryToApply !== selectedCategory) changeCategory(categoryToApply);
    if (orderToApply !== selectedOrder) sortBy(orderToApply);
    closeMenu();
  };

  const isButtonDisabled = () => {
    if (selectedFilter === 'filter')
      return shouldDisableCategoryButton({ categoryToApply, selectedCategory });
    if (selectedFilter === 'order')
      return shouldDisableOrderButton({ orderToApply, selectedOrder });

    return false;
  };

  return (
    <div className="w-full flex justify-center relative lg:tw-hidden">
      <FloatingButtons
        onCategoryClick={onCategoryFilterClick}
        onSortingClick={onSortingClick}
      />

      <FloatingMenu
        isOpen={openMenu}
        isButtonDisabled={isButtonDisabled()}
        onMenuClosed={() => setOpenMenu(false)}
        onButtonClicked={handleFilterApplied}
      >
        {selectedFilter === 'filter' && (
          <CategoryList
            categories={categories}
            selectedCategory={categoryToApply}
            onCategoryChange={setCategoryToApply}
          />
        )}

        {selectedFilter === 'order' && (
          <SortOptionList
            options={ORDER_OPTIONS}
            selectedOption={orderToApply}
            onSortOptionChange={setOrderToApply}
          />
        )}
      </FloatingMenu>
    </div>
  );
};
