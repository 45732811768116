import { FC } from 'react';

import { Category } from '@/model/Category';

import { useBranding } from '../../../../../shared/hooks/useBranding';
import { useHomeTranslation } from '../../../hooks';
import { FloatingMenuTitle } from '../FloatingMenuTitle';

import { isSelected } from './utils/isSelected';
import { CategoryName } from './CategoryName';

type Props = {
  categories: Category[];
  selectedCategory: Category | null;
  onCategoryChange: (category: Category) => void;
};

export const CategoryList: FC<React.PropsWithChildren<Props>> = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  const { primaryColor } = useBranding();
  const { t } = useHomeTranslation();

  return (
    <div className="flex flex-col w-full h-full" title="Filter" role="list">
      <FloatingMenuTitle title={t('FILTER_RESULTS')} />
      <h3 className="font-subtitle2 mb-16">{t('CATEGORIES_LABEL')}</h3>
      <div className="relative flex-grow overflow-y-scroll">
        <ul className="absolute h-full">
          {categories.map(category => (
            <li className="font-body3 mb-16" key={category._id}>
              <button type="button" onClick={() => onCategoryChange(category)}>
                <CategoryName
                  isSelected={isSelected(category, selectedCategory)}
                  primaryColor={primaryColor}
                >
                  {category.name}
                </CategoryName>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
