import { CATALOG } from '@/mocks/catalog';

import { ALL_CATEGORIES_ID, PRODUCTS_PER_PAGE } from '../constants';
import { SearchProducts } from '../types';

import { sortProducts } from './sortProducts';

// We will call backend here to get the products with the filters applied
export const searchProducts: SearchProducts = async ({
  category,
  order,
  amount = PRODUCTS_PER_PAGE,
  offset = 0,
}) => {
  const catalogFilterByCatagory =
    category && category._id !== ALL_CATEGORIES_ID
      ? CATALOG.filter(product => product.category._id === category._id)
      : CATALOG;

  const sortedProducs = sortProducts(catalogFilterByCatagory, order);

  return {
    products: sortedProducs.slice(offset, offset + amount),
    total: sortedProducs.length,
  };
};
