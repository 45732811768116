import { createContext } from 'react';

import { Category } from '@/model';

interface ContextProps {
  allCategories: Category;
  categories: Category[];
  selectedCategory: Category;

  changeCategory: (category: Category) => void;
}

export const CategoryFilterContext = createContext({} as ContextProps);
