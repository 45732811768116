import { FC } from 'react';

import { ArrowIosUp } from '@crehana/icons/outline';

import { useHomeTranslation } from '../../../hooks/useHomeTranslation';

export const BackToTop: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useHomeTranslation();

  const onScrollUp = () => {
    document
      .getElementById('main-cotent')
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={onScrollUp}
      className="font-link flex items-center cursor-pointer"
    >
      <span className="mr-16">
        <ArrowIosUp size={24} />
      </span>
      {t('BACK_TO_TOP')}
    </div>
  );
};
