import { useCallback, useMemo, useState } from 'react';

import useMarketplaceAmplitudeEvent from '@/shared/hooks/useMarketplaceAmplitudeEvent';

import { ORDER_OPTIONS } from '../../constants';
import { SortOption, SortPathsEvent } from '../../types';

import { SortingContext } from './SortingContext';

export const SortingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const orders = useMemo(() => ORDER_OPTIONS, []);
  const logEvent = useMarketplaceAmplitudeEvent();
  const [selectedOrder, setSelectedOrder] = useState<SortOption>(orders[0]);

  const sortBy = useCallback(
    (option: SortOption) => {
      if (
        option.field === selectedOrder.field &&
        selectedOrder.direction === option.direction
      )
        return;

      logEvent<SortPathsEvent>('Click Sort eCommerce', {
        sortBy: option.field,
        direction: option.direction,
      });
      setSelectedOrder(option);
    },
    [logEvent, selectedOrder.direction, selectedOrder.field],
  );

  return (
    <SortingContext.Provider
      value={{
        selectedOrder,
        orders,
        sortBy,
      }}
    >
      {children}
    </SortingContext.Provider>
  );
};
