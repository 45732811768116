import { CatalogProduct } from '../../../model/CatalogProduct';
import { ProductFieldEnum } from '../../../shared/types/graphql/globals.highereducation';
import { SortOption } from '../types';

export const sortProducts = (products: CatalogProduct[], order: SortOption) => {
  switch (order.field) {
    case ProductFieldEnum.TITLE:
      return products.sort((a, b) => a.title.localeCompare(b.title));
    case ProductFieldEnum.BEST_SELLER:
      return products.sort((a, b) => a.price - b.price);
    case ProductFieldEnum.CREATED_AT:
      return products.sort((a, b) =>
        a.category.name.localeCompare(b.category.name),
      );
  }
};
