import { FC } from 'react';

import { Category } from '@/model';
import { ALL_CATEGORIES_ID } from '@/views/Ecommerce/constants';

import { useHomeTranslation } from '../../../hooks';

type Props = {
  currentCategory: Category | null;
};

export const CatalogHeader: FC<React.PropsWithChildren<Props>> = ({
  currentCategory,
}) => {
  const { t } = useHomeTranslation();

  if (!currentCategory) return null;

  return (
    <header>
      <h2 className="font-h2 mb-8 lg:tw-mb-2">
        {currentCategory._id !== ALL_CATEGORIES_ID ? (
          <>
            {t('CATALOG_ROUTES_CATEGORY_LABEL')}{' '}
            <span className="text-primary-main">{currentCategory?.name}</span>
          </>
        ) : (
          t('ALL_CATEGORIES_LIST')
        )}
      </h2>
    </header>
  );
};
