import { I18nTranslation } from '@crehana/i18n';

import { HOMEPAGE_PAGE_KEY } from '@/views/Ecommerce/constants';

import es from './localeEs';
import pt from './localePt';

export type TKeys = keyof (typeof es)[typeof HOMEPAGE_PAGE_KEY];

const locales: I18nTranslation = { es, pt };

export default locales;
